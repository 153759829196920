import React, { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import smoothscroll from 'smoothscroll-polyfill';
import './styles/reset.css';
import './styles/index.css';
import './styles/msg.css';
import Header from './components/header';
import Footer from './components/footer';
import { HoomanKnowledge, HoomanTextMsg, NameCard, TS, ViewerTextMsg } from './components/msgs';
import { isDesktop } from './utils';
import data from './data';

smoothscroll.polyfill();
gsap.registerPlugin(ScrollTrigger);

const messages = data.main.map(pageData => pageData.data.find(msg => msg.type === 'response')?.value).slice(1);


const Page = memo(({page, index, sendButtonHeight, nextPage}) => {
  const ref = useRef();

  useEffect(() => {
    const threshold = 75;
    gsap.to(ref.current, {
      scrollTrigger: {
        trigger: `.page${index} > *`,
        scroller: '.mainWrapper',
        start: `top ${threshold}%`,
        end: `end ${threshold}%`,
        onLeave() {
          nextPage(index);
        },
        onLeaveBack() {
          nextPage(index-1);
        },
        onEnter() {
          nextPage(index);
        },
        onEnterBack() {
          nextPage(index-1);
        }
      },
      opacity: 1,
    });
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isFirstPage = index === 0;
  return (
    <div className={`slide page${index}`} id={page.anchor} ref={ref} style={{
      opacity: 0.05,
      padding: isDesktop()? 0 : 'var(--distanceTop) 0 0',
      height: isFirstPage? sendButtonHeight : 'auto',
      flexDirection: isFirstPage && 'column-reverse'
    }}>
      {page.data.map((el, idx) => {
        const El = otherComponents[el.type] || HoomanKnowledge;
        return <El {...el} key={idx} />
      })}
      { isFirstPage && <TS /> }
    </div>
  );
});


var otherComponents = {
  'text': HoomanTextMsg,
  'response': ViewerTextMsg,
  'NameCard': NameCard,
}

function Index() {
  const [msgs, setMsg] = useState(null);
  const [theme, setTheme] = useState('dark');
  const isDarkTheme = theme === 'dark';
  const [sendButtonHeight, setSendButtonHeight] = useState();

  useLayoutEffect(() => {
    setSendButtonHeight(document.querySelector('.footer')?.getBoundingClientRect().top - 100 - 16);
    setTimeout(() => {
      setMsg(0);
    }, 1);
  }, [])

  return (
    <>
      <Header 
        theme={theme} 
        setTheme={setTheme} 
        socials={data.header.socials} 
        navigations={data.header.nav} 
        currentPage={data.main[msgs]?.anchor}
      />
      <main className='mainWrapper'>
        {data.main.map((page, index) => <Page 
          key={index} 
          page={page} 
          index={index} 
          nextPage={setMsg}
          sendButtonHeight={sendButtonHeight}
          
        />
      )}
      </main>
      <Footer 
        isDarkTheme={isDarkTheme} 
        msgs={msgs} 
        messages={messages} 
        socials={data.footer.socials} 
        goToTopIcon={data.footer.goToTopIcon} 
        mail={data.footer.mail} 
      />
    </>
  );
}

export default Index;
