import gsap from "gsap";
import { useLayoutEffect, useRef, useState } from "react";

export function debounce(func, delay = 150) {
  let timeoutId = null;
  return function debounced(...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, [...args]);
    }, delay);
  };
}


export function useRender() {
  const [,render] = useState(1);
  useLayoutEffect(() => {
    const debounced = debounce(() => {
      render(prev => prev - 1)
    });
    window.addEventListener('resize', debounced)
    return () => {
      window.removeEventListener('resize', debounced)
    };
  }, []);
}

export function isDesktop () {
  return window.matchMedia('screen and (min-width: 821px)').matches;
}

export function useAnimation() {
  const ref = useRef();
  useLayoutEffect(() => {
    let gs = gsap.from(ref.current, {
      scrollTrigger: {
        trigger: ref.current,
        scroller: '.mainWrapper',
        start: 'top 80%',
        end: 'top 70%',
        scrub: true,
        // markers: true
      },
      opacity: 0,
      translateY: 35,
      transformOrigin: 'bottom',
      duration: .5
    })
    return () => {
      gs.kill()
    };
  }, []);
  
  return ref;
};

export function navigateToSection(selector) {
  try {
    document.querySelector(selector).scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  } catch (e) { }
};

export const themeColors = {
  light: {
    'LBlack': '#060201',
    'LWhite': '#fff',
    'LWhiteHex': '255,255,255',
    'LlightGray': '#F2F2F7',
    'LGrayShadow': '#8E8E93',
    'LGrayAsh': '#D1D1D6',
    'LGrayCarbon': '#636363',
    'LGrayCobalt': '#AEAEB2',
    'LBlue': '#007AFF',
    'LNavBack': '#F2F2F7',
    'LNavItem': '#fff',
    'LWTH': '#636363',
    'LWTH2': '#F2F2F7',
  },
  dark: {
    'LBlack': '#fff',
    'LWhite': '#060201',
    'LWhiteHex': '06,02,01',
    'LlightGray': '#26252A',
    'LGrayShadow': '#8E8E93',
    'LGrayAsh': '#3B3B3B',
    'LGrayCarbon': '#8E8E93',
    'LGrayCobalt': '#636363',
    'LBlue': '#2A90FE',
    'LNavBack': '#060201',
    'LNavItem': '#26252A',
    'LWTH': '#fff',
    'LWTH2': '#3B3B3B',
  }
};