import React from 'react'
import { ReactComponent as Tail } from '../assets/svg/Tail.svg';
import { ReactComponent as BlueTail } from '../assets/svg/BlueTail.svg';
import I from '../assets/svg/I.svg';
import { useAnimation } from '../utils';
import Img from './Img';

export function ViewerTextMsg({ value, anchor }) {
    const ref = useAnimation();
    return (
        <div ref={ref} id={anchor} className='textMsgBase blueTextMsg'>
            {value}
            <BlueTail className='blueJigool' />
        </div>
    );
}

export function HoomanTextMsg({ value, anchor }) {
    const ref = useAnimation();
    return (
        <div ref={ref} id={anchor} className='textMsgBase'>
            {value}
            <Tail className='jigool' />
        </div>
    );
}

export function HoomanKnowledge({
    type = 'knowledge',
    title,
    leadImage,
    leadText,
    subTitle,
    avatarImg,
    avatarAltText,
    fileUrl,
    fileDownloadText = 'Get',
    description,
    seperatorLeftText,
    seperatorRightText,
    anchor,
    externalList = [],
    appList = [],
}) {
    const ref = useAnimation();

    return (
        <div ref={ref} id={anchor} className='knowledgeCard' >
            <Tail className='jigool' />
            {leadImage && <Img src={leadImage} className='showcase_intro' />}
            {type && !leadImage && <div className='knowledgeCard_infoTitle'>
                <Img src={I} style={{ marginRight: '4px' }} alt='' />
                {type.toUpperCase()}
            </div>}
            {(title || subTitle || avatarImg || fileUrl) &&
                <div className='df aic knowledgeCard_doubleP'>
                    {avatarImg && <Img src={avatarImg} width='64' className='knowledgeCard_doubleP-avatar' alt={avatarAltText} />}
                    {(title || subTitle) && <div className='df fdr-c jcc'>
                        {title &&
                            <p className='knowledgeCard_title'>
                                {title}
                            </p>
                        }
                        {subTitle && <p className='knowledgeCard_subtitle'>{subTitle}</p>}
                    </div>}
                    {fileUrl && <a href={fileUrl} className='knowledgeCard_download hovered' download>{fileDownloadText}</a>}
                </div>
            }
            {description &&
                <p className='knowledgeCard_description'>
                    {description}
                </p>
            }
            {leadText &&
                <p className='showcase_title'>{leadText}</p>
            }
            {(seperatorLeftText || seperatorRightText) &&
                <div className='knowledgeCard_sepratorText df jcbw'>
                    <span>{seperatorLeftText}</span>
                    <span>{seperatorRightText}</span>
                </div>
            }
            {externalList && externalList?.map((item, index) => (
                <Item1 img={item.img} title={item.title} description={item.description} url={item.url} key={index} />
            ))}
            {appList && appList?.map((item, index) => (
                <Item2 key={index} img={item.img} title={item.title} description={item.description} creator={item.creator} link={item.link} linkText={item.linkText} />
            ))}
        </div>
    );
}

export function NameCard({
    title,
    spelling,
    subTitle,
    description,
    anchor,
    extraSections = [
        {
            title,
            spelling,
            subTitle,
            description,
        }
    ]
}) {
    const ref = useAnimation();

    return (
        <div ref={ref} id={anchor} className='namecard' >
            <Tail className='jigool' />
            <h3 className='namecard_title'>{title}</h3>
            <p className='namecard_spelling'>{spelling}</p>
            <h4 className='namecard_grey'>{subTitle}</h4>
            <p className='namecard_description'>{description}</p>
            {extraSections.map((data, index) => (
                <div className='namecard_extraSection' key={index}>
                    <p className='namecard_spelling'>{data.spelling}</p>
                    <h4 className='namecard_grey'>{data.subTitle}</h4>
                    <p className='namecard_description'>{data.description}</p>
                </div>
            ))}
        </div>
    );
}

export function Item1({ title, description, img, url }) {
    return (
        <a href={url} className='item1 df jcbw' target="_blank" rel="noreferrer" >
            <div className='df fdr-c hovered'>
                <p className='item1_title'>{title}</p>
                <p className='item1_description'>{description}</p>
            </div>
            <Img src={img} className='item1_img hovered' width='36' height='36' />
        </a>
    );
}

export function Item2({ title, description, creator, img, link, linkText = 'Get' }) {
    return (
        <a href={link} className='item2 df aic' target="_blank" rel="noreferrer">
            <div className='item2_img'>
                <Img src={img} width='36' />
            </div>
            <div className='df aic item2_wrapper'>
                <div className='df fdr-c hovered'>
                    <p className='item2_title'>{title}</p>
                    <p className='item2_description'>{description}</p>
                    <p className='item2_description'>{creator}</p>
                </div>
                {link && 
                    <a href={link} className='knowledgeCard_download hovered' target="_blank" rel="noreferrer">{linkText}</a>
                }
            </div>
        </a>
    );
}

export function TS() {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const date = new Date();
    return (
        <div className='ts'>
            {
                days[date.getDay()] + ", " + months[date.getMonth()] + " " + date.getDate() + ", " + date.toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                })
            }
        </div>
    );
}