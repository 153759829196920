import React, { memo, useLayoutEffect, useRef } from 'react'
import { ReactComponent as Email } from '../assets/svg/Email.svg';
import SendButton from '../assets/svg/Send button.svg';
import gsap from 'gsap';
import Img from './Img';
import { navigateToSection } from '../utils';

// ? fixes scroll jank
let once = true;


function Footer({ isDarkTheme, msgs, messages = [], socials=[], goToTopIcon, mail }) {
    const ref = useRef();
    const ref2 = useRef();
    useLayoutEffect(() => {
        if (once && (msgs === messages.length)) {
            once = false;
            const sc = {
                trigger: `.page${msgs}`,
                scroller: '.mainWrapper',
                start: 'top 90%',
                end: '50% 80%',
                scrub: true,
            };
            gsap.to(ref2.current, {
                scrollTrigger: sc,
                left: () => {
                    return document.querySelector('#email').getBoundingClientRect().right + 12
                },
                opacity: 1,
            })
            gsap.to(ref.current, {
                scrollTrigger: sc,
                opacity: 0,
                translateX: '-120%',
                transformOrigin: 'bottom',
            });
        } else {
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [msgs]);

    function FooterSocialItem({ link, image, alt }) {
        return (
            <a href={link} className="footer_socials-item hovered" target="_blank" rel="noreferrer">
                <Img src={image} alt={alt} />
            </a>
        );
    }

    return (
        <footer className={`footer`}>
            <a href={`mailto:${mail}`} target="_blank" rel="noreferrer">
                <Email id="email" className={`hovered ${isDarkTheme ? 'footer_email--dark' : 'footer_email--light'}`} />
            </a>
            <div ref={ref} key={12345688} style={{ flexGrow: 2, width: '100%' }}>
                <button className='sendButton hovered' onClick={() => {
                    navigateToSection(`.page${msgs + 1}`);
                }} >
                    {messages[msgs]}
                    <Img src={SendButton} className='sendArrowUp' />
                </button>
            </div>
            <div ref={ref2} key={1234522238} className='footer_socials'>
                {goToTopIcon && <div className="footer_socials-item hovered" onClick={()=>navigateToSection(`.page0`)}>
                    <Img src={goToTopIcon}/>
                </div>}
                {socials.map((socialItem, index) => (
                    <FooterSocialItem
                        key={index}
                        link={socialItem.url}
                        image={socialItem.img}
                        alt={socialItem.alt}
                    />
                ))}
            </div>
        </footer>
    )
}


export default memo(Footer);