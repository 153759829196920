//? regular imports 
import HoomanAvatar from './assets/images/hoomanavatar.png';
import ArunAvatar from './assets/images/arunavatar.png';
import MarkAvatar from './assets/images/markavatar.png';
import MahmoudAvatar from './assets/images/mahmoudavatar.png';
import CocoAvatar from './assets/images/cocoavatar.png';
import Showcase1 from './assets/images/showcase1.png';
import Showcase2 from './assets/images/showcase2.png';
import Article1 from './assets/images/snapptrip-article.png';
import Linkedin from './assets/svg/social/Linkedin.svg';
import Twitter from './assets/svg/social/Twitter.svg';
import Dribbble from './assets/svg/social/Dribbble.svg';
import Instagram from './assets/svg/social/Instagram.svg';
import Medium from './assets/svg/social/Medium.svg';
import Goodreads from './assets/svg/social/Goodreads.svg';
import Spotify from './assets/svg/social/Spotify.svg';
import Behance from './assets/svg/social/Behance.svg';
import Twitch from './assets/svg/social/Twitch.svg';
import BE from './assets/svg/footer/behance_rounded.svg';
import TH from './assets/svg/footer/twitch_rounded.svg';
import DR from './assets/svg/footer/dribbble_rounded.svg';
import ME from './assets/svg/footer/medium_rounded.svg';
import SP from './assets/svg/footer/spotify_rounded.svg';
import TW from './assets/svg/footer/twitter_rounded.svg';
import IN from './assets/svg/footer/instagram_rounded.svg';
import GR from './assets/svg/footer/goodreads_rounded.svg';
import LI from './assets/svg/footer/linkedin_rounded.svg';
import GoToTop from './assets/svg/footer/go_top.svg';
import H8man from './assets/svg/app_icn/hooman_large.svg';
import Medium_Large from './assets/svg/app_icn/medium_large.svg';
import Dribbble_Large from './assets/svg/app_icn/dribbble_large.svg';
import Behance_Large from './assets/svg/app_icn/behance_large.svg';
import Virgool_Large from './assets/svg/app_icn/virgool_large.svg';
import Linkedin_Large from './assets/svg/app_icn/linkedin_large.svg';
import Spotify_Large from './assets/trivia/apps/spotify_large.svg';
import Discord_Large from './assets/trivia/apps/discord_large.svg';
import Sheets_Large from './assets/trivia/apps/googleSheets_large.svg';
import Notion_Large from './assets/trivia/apps/notion_large.svg';
import Shortcuts_Large from './assets/trivia/apps/shortcuts_large.svg';
import Warcraft from './assets/trivia/games/wc3.png';
import Dota2 from './assets/trivia/games/dota2.png';
import Inside from './assets/trivia/games/inside.png';
import Gris from './assets/trivia/games/gris.png';
import Zelda from './assets/trivia/games/zelda.png';
import Archive from './assets/trivia/music/again.png';
import James from './assets/trivia/music/barefoot.png';
import Luna from './assets/trivia/music/be_the_one.png';
import Ghostly from './assets/trivia/music/empty_note.png';
import Fang from './assets/trivia/music/you_are_the_one.png';
import Bruges from './assets/trivia/movies/in_bruges.png';
import Detachment from './assets/trivia/movies/detachment.png';
import Pulp from './assets/trivia/movies/pulp_fiction.png';
import Jagten from './assets/trivia/movies/jagten.png';
import Earth from './assets/trivia/movies/another_earth.png';
import Animal from './assets/trivia/books/animal_farm.png';
import Art from './assets/trivia/books/art.png';
import Blind from './assets/trivia/books/blindness.png';
import Carnage from './assets/trivia/books/god_of_carnage.png';
import Immortal from './assets/trivia/books/immortality.png';
import CV from './assets/Hooman Hatefi CV.pdf';


//! special import, allows changing svg fill based on theme
import { ReactComponent as PersonRound } from './assets/svg/PersonRound.svg';
import { ReactComponent as Article } from './assets/svg/Article.svg';
import { ReactComponent as Trivia } from './assets/svg/Trivia.svg';
import { ReactComponent as Download } from './assets/svg/Download.svg';
import { ReactComponent as Info2 } from './assets/svg/Info2.svg';
import { ReactComponent as CaseStudy } from './assets/svg/CaseStudy.svg';



//? info:
// anchor attribute can be set on page or element ( like cv )
// and then should be referd from header accordingly

const data = {
    header: {
        nav: [
            [
                {
                    text: 'Intro',
                    svgIcon: PersonRound,
                    anchor: 'intro_page'
                },
            ],
            [
                {
                    text: 'Case studies',
                    svgIcon: CaseStudy,
                    anchor: 'case_page'
                },
                {
                    text: 'Articles',
                    svgIcon: Article,
                    anchor: 'articles_page'
                },
            ],
            [
                {
                    text: 'About me',
                    svgIcon: Info2,
                    anchor: 'about_page'
                },
                {
                    text: 'Trivia',
                    svgIcon: Trivia,
                    anchor: 'trivia_page'
                },
                {
                    text: 'My CV',
                    svgIcon: Download,
                    externalLink: CV,
                    download: true
                },
            ],
        ],
        socials: [
            {
                text: 'LinkedIn',
                icon: Linkedin,
                url: 'https://www.linkedin.com/in/h8man'
            },
            {
                url: 'https://www.behance.net/h8man',
                icon: Behance,
                text: 'Behance',
            },
            {
                url: 'https://dribbble.com/H8man',
                icon: Dribbble,
                text: 'Dribbble',
            },            
            {
                url: 'https://h8man.medium.com',
                icon: Medium,
                text: 'Medium',
            },
            {
                url: 'https://www.twitch.tv/h8man13',
                icon: Twitch,
                text: 'Twitch',
            },
            {
                url: 'https://open.spotify.com/user/hooman13',
                icon: Spotify,
                text: 'Spotify',
            },
            {
                url: 'https://twitter.com/h8man',
                icon: Twitter,
                text: 'Twitter',
            },
            {
                url: 'https://www.instagram.com/h8man.hatefi',
                icon: Instagram,
                text: 'Instagram',
            },
            {
                url: 'https://www.goodreads.com/user/show/10197210-hooman-hatefi',
                icon: Goodreads,
                text: 'Goodreads',
            }
        ]
    },
    main: [
        {
            anchor: 'intro_page',
            data: [
                {
                    type: 'knowledge',
                    title: 'Hooman Hatefi',
                    subTitle: 'Senior Director, Design at Snapp and Design Teacher at Inverse School',
                    avatarImg: HoomanAvatar,
                    fileDownloadText: 'Get',
                    seperatorLeftText: 'Get in touch',
                    seperatorRightText: 'hooman.hatefi@gmail.com',
                    externalList: [
                        {
                            img: H8man,
                            title: 'Official website',
                            url: 'https://www.h8man.com',
                            description: 'h8man.com'
                        }
                    ],
                    description: 'A senior design director, teacher and consultant. I aspire to motivate designers, researchers and writers to reach simple, innovative and user-centered experiences.'
                },
                {
                    type: 'text',
                    value: 'Hey, it’s Hooman here. 👋🏼'
                },
            ]
        },
        {
            anchor: 'case_page',
            data: [
                {
                    type: 'response',
                    value: 'Hi, any portfolio I can see? 😎',
                },
                {
                    type: 'text',
                    value: 'Of course! 👍🏼'
                },
                {
                    type: 'text',
                    value: 'Here’s a handful of what I’ve written:'                    
                },
                {
                    type: 'knowledge',
                    leadImage: Showcase2,
                    leadText: 'Snapp super app homepage revamp — a Case Study',
                    seperatorLeftText: 'Hooman Hatefi, Ehsan Madadi, Nayyereh Afzali and +3',
                    seperatorRightText: '6, Dec, 2021',
                    externalList: [
                        {
                            img: Medium_Large,
                            title: 'Medium',
                            url: 'https://h8man.medium.com/snapp-super-app-homepage-revamp-a-case-study-6995f443a921',
                            description: 'medium.com'
                        },
                        {
                            img: Behance_Large,
                            title: 'Behance',
                            url: 'https://www.behance.net/gallery/132609413/Snapp-super-app-homepage-revamp-a-Case-Study',
                            description: 'behance.net'
                        }, 
                        {
                            img: Dribbble_Large,
                            title: 'Dribbble',
                            url: 'https://dribbble.com/shots/17340912-Snapp-super-app-homepage-revamp-a-Case-Study',
                            description: 'behance.net'
                        },                                    
                    ]
                },
                {
                    type: 'knowledge',
                    leadImage: Showcase1,
                    leadText: 'Figma Mirror App Redesign — a Product Design Case Study',
                    seperatorLeftText: 'Hooman Hatefi, Farshid Darvishi and Zinat Farahani',
                    seperatorRightText: '25, Mar, 2021',
                    externalList: [
                        {
                            img: Medium_Large,
                            title: 'Medium',
                            url: 'https://h8man.medium.com/figma-mirror-app-redesign-a-product-design-case-study-2e9399ba1de1',
                            description: 'medium.com'
                        },
                        {
                            img: Behance_Large,
                            title: 'Behance',
                            url: 'https://www.behance.net/gallery/116152061/Figma-Mirror-App-Redesign-a-Product-Design-Case-Study',
                            description: 'behance.net'
                        },
                        {
                            img: Dribbble_Large,
                            title: 'Dribbble',
                            url: 'https://dribbble.com/shots/15356683-Figma-Mirror-App-Redesign-a-Product-Design-Case-Study',
                            description: 'dribbble.com'
                        },
                        {
                            img: Virgool_Large,
                            title: 'Virgool',
                            url: 'https://virgool.io/@h8man/%D8%A8%D8%A7%D8%B2%D8%B7%D8%B1%D8%A7%D8%AD%DB%8C-%D8%A7%D9%BE%D9%84%DB%8C%DA%A9%DB%8C%D8%B4%D9%86-figma-mirror-%D8%A8%D8%B1%D8%B1%D8%B3%DB%8C-%D9%85%D9%88%D8%B1%D8%AF%DB%8C-%D8%B7%D8%B1%D8%A7%D8%AD%DB%8C-%D9%85%D8%AD%D8%B5%D9%88%D9%84-igwlp4qmszvd',
                            description: 'virgool.io'
                        }
                    ]
                },                
            ]
        },
        {
            anchor: 'articles_page',
            data: [
                {
                    type: 'response',
                    value: 'Any online articles? 🤔',
                },
                {
                    type: 'text',
                    value: 'Yup yup'
                },
                {
                    type: 'text',
                    value: 'Here’s an article I’ve written about the turning points of my presence, projects, friends, and colleagues in SnappTrip.'
                },
                {
                    type: 'knowledge',
                    leadImage: Article1,
                    leadText: 'The years I lived with Snapp — Part One: SnappTrip',
                    seperatorLeftText: 'Hooman Hatefi',
                    seperatorRightText: '29, Jan, 2022',
                    externalList: [
                        {
                            img: Medium_Large,
                            title: 'Medium',
                            url: 'https://h8man.medium.com/the-years-i-lived-with-snapp-part-one-snapptrip-33d11e666a83',
                            description: 'medium.com'
                        },              
                    ]
                },
            ]
        },
        {
            anchor: 'about_page',
            data: [
                {
                    type: 'response',
                    value: 'Tell me about yourself. ☺',
                },
                {
                    type: 'text',
                    value: 'Glad you asked! ☺'
                },
                {
                    type: 'text',
                    value: 'Let me tell you about my name first:'
                },
                {
                    type: 'NameCard',
                    title: 'Hooman',
                    spelling: 'Hooman | humæn |',
                    subTitle: 'NOUN',
                    description: 'is a Persian male name which means benevolent and good natured.',
                    extraSections: [
                        {
                            spelling: 'Hooman | humæn |',
                            subTitle: 'NOUN',
                            description: 'A hooman is a funny way to spell the word “human”.',
                        }
                    ]
                },
                {
                    type: 'text',
                    value: 'A jack of all trades! From leading Snapp design team to teaching design enthusiasts. I thrive on motivating and inspiring people to do amazing stuff and create fantastic experiences.'
                },
                {
                    type: 'text',
                    value: 'Here’s what my managers think of me!'
                },
                {
                    type: 'text',
                    value: 'Let’s start with Mahmoud, Whom I’ve always been fond of his useful points and great advices throughout my career at Snapp:'
                },
                {
                    type: 'knowledge',
                    title: 'Dr. Mahmoud Fouz',
                    subTitle: 'Executive Chairman at Jeeny, Co-Founder and Chairman at Snapp',
                    avatarImg: MahmoudAvatar,
                    seperatorRightText: '26, Dec, 2021',
                    externalList: [
                        {
                            img: Linkedin_Large,
                            title: 'Linkedin',
                            url: 'https://www.linkedin.com/in/dr-mahmoud-fouz-ba866845',
                            description: 'linkedin.com/mahmoud-fouz'
                        }
                    ],
                    description: '“Hooman has brought the UX of our products to a new level. He ensured that different independently developed services still fit together to give the users a unified experience in our superapp. His passion speaks out in the work while he always stays calm and humble.”'
                },                
                {
                    type: 'text',
                    value: 'Arun’s my current manager at Snapp and we have reached many exceptional milestones together. Let’s read what he thinks about me:'
                },                
                {
                    type: 'knowledge',
                    title: 'Arun Sharma',
                    subTitle: 'COO at Snapp',
                    avatarImg: ArunAvatar,
                    seperatorRightText: '09, Nov, 2021',
                    externalList: [
                        {
                            img: Linkedin_Large,
                            title: 'Linkedin',
                            url: 'https://www.linkedin.com/in/arun-sharma-b3851671',
                            description: 'linkedin.com/arun-sharma'
                        }
                    ],
                    description: '“Hooman has been able to build a large multidisciplinary design team at Snapp, which stands at a scale of more than 3 million daily active users, with product spanning across multiple native, web and back office platforms. Responsible for setting up design processes, vision and strategy he has executed exceptionally well on both aesthetics and utility of core product, while building a solid team, displaying great leadership and mentoring skills. We often faced situations where we needed fast deliveries of high impact features, and Hooman and team have always responded very effectively delivered flawlessly. A great team player, capable of building a good strategic vision, and adopting to hands-on tactical approach when needed, with commendable cross team, he is core to our business eco-system.”'
                },
                {
                    type: 'text',
                    value: 'And then we come to Mark. My previous manager, who taught me how to think and act like a business man:',
                    anchor: 'cv_page'
                },
                {
                    type: 'knowledge',
                    title: 'Mark Clift',
                    subTitle: 'Ex-CEO at Snapptrip and Group CPO at Snapp Group',
                    avatarImg: MarkAvatar,
                    seperatorRightText: '08, Mar, 2021',
                    externalList: [
                        {
                            img: Linkedin_Large,
                            title: 'Linkedin',
                            url: 'https://www.linkedin.com/in/mark-clift',
                            description: 'linkedin.com/mark-clift'
                        }
                    ],
                    description: '“It was a pleasure to work with Hooman, who grew into a superstar head of design over the course of the few years I worked with him in the fastest-growing business in the Middle East. He deeply understood the customer but was pragmatic in his approach to interface and experience design. He was well-respected by everyone who interacted with him, from product to operations to international marketing agencies.”'
                },
                {
                    type: 'text',
                    value: 'Now that you’ve known me better, let’s read my CV.'                    
                },                
                {
                    type: 'knowledge',
                    title: 'Download my CV',
                    subTitle: '1, Jan, 2022',
                    avatarImg: HoomanAvatar,
                    fileUrl: CV,
                    fileDownloadText: 'Get',
                },
                {
                    type: 'text',
                    value: 'PS: You can access my CV anytime you want from the side menu. 😁'                    
                },
            ]
        },
        // {
        //     anchor: 'cv_page',
        //     data: [
        //         {
        //             type: 'response',
        //             value: 'May I have your resume?',
        //         },
        //         {
        //             type: 'text',
        //             value: 'yeah sure, here you go',
        //         },
        //         {
        //             type: 'knowledge',
        //             title: 'Donwload CV',
        //             subTitle: '4 August 2021',
        //             avatarImg: HoomanAvatar,
        //             fileUrl: 'sf',
        //             fileDownloadText: 'Get',
        //         },
        //     ]
        // },
        {
            anchor: 'trivia_page',
            data: [
                {
                    type: 'response',
                    value: 'Some fun facts about you? 💡',
                },
                {
                    type: 'text',
                    value: 'Lol, didn’t see that coming... 😅'
                },
                {
                    type: 'text',
                    value: 'OK, let me first introduce you, the majesty of the royal household:'
                },
                {
                    type: 'knowledge',
                    title: 'Rococo',
                    subTitle: 'AKA Coco',
                    avatarImg: CocoAvatar,
                    description: 'If you want me to come over there, you need to shake a bag of treats or open a can of something. Cause I’m impawsibly cute, hooman!'
                },
                {
                    type: 'text',
                    value: 'I like to put my stamp of approval on different things for my friends.'
                },
                {
                    type: 'text',
                    value: 'Starting with the top 5 apps or products I admire. 📱'
                },
                {
                    type: 'Apps I Admire',
                    appList: [
                        {
                            img: Spotify_Large,
                            title: 'Spotify',
                            description: 'Listen to songs and playlists',
                            link: 'https://www.spotify.com',
                            linkText: 'Get'
                        },
                        {
                            img: Notion_Large,
                            title: 'Notion',
                            description: 'The all-in-one workspace',
                            link: 'https://www.notion.so',
                            linkText: 'Get'
                        },
                        {
                            img: Shortcuts_Large,
                            title: 'Shortcuts',
                            description: 'Do more with your apps',
                            link: 'https://apps.apple.com/us/app/shortcuts/id915249334',
                            linkText: 'Get'
                        },
                        {
                            img: Discord_Large,
                            title: 'Discord',
                            description: 'Communities and gaming',
                            link: 'https://discord.com',
                            linkText: 'Get'
                        },
                        {
                            img: Sheets_Large,
                            title: 'Sheets',
                            description: 'Edit & share spreadsheets',
                            link: 'https://docs.google.com/spreadsheets',
                            linkText: 'Get'
                        },
                    ],
                },
                {
                    type: 'text',
                    value: 'Then let me share my top 5 games. I spent some of my good old days playing these. 🎮'
                },
                {
                    type: 'Games I’m fond of',
                    appList: [
                        {
                            img: Warcraft,
                            title: 'Warcraft III',
                            description: '2003',
                            creator: 'Blizzard Entertainment',
                            link: 'https://playwarcraft3.com/en-gb',
                            linkText: 'View'
                        },
                        {
                            img: Dota2,
                            title: 'Dota 2',
                            description: '2013',
                            creator: 'Valve Corporation',
                            link: 'https://www.dota2.com',
                            linkText: 'View'
                        },
                        {
                            img: Inside,
                            title: 'Inside',
                            description: '2016',
                            creator: 'Playdead',
                            link: 'https://playdead.com/games/inside',
                            linkText: 'View'
                        },
                        {
                            img: Gris,
                            title: 'Gris',
                            description: '2018',
                            creator: 'Nomada Studio',
                            link: 'https://nomada.studio',
                            linkText: 'View'
                        },
                        {
                            img: Zelda,
                            title: 'The Legend of Zelda',
                            description: '2017',
                            creator: 'Nintendo Entertainment',
                            link: 'https://www.zelda.com/breath-of-the-wild',
                            linkText: 'View'
                        },
                    ],
                },
                {
                    type: 'text',
                    value: 'And these 5 songs were the soundtracks to my remarkable moments. 🎧'
                },
                {
                    type: 'SONGS I PUT ON REPEAT',
                    appList: [
                        {
                            img: Ghostly,
                            title: 'Empty Note',
                            creator: 'Ghostly Kisses',
                            link: 'https://www.youtube.com/watch?v=AZCzfLMHUQs',
                            linkText: 'View'
                        },
                        {
                            img: Archive,
                            title: 'Again',
                            creator: 'Archive',
                            link: 'https://www.youtube.com/watch?v=EkhY4YXX6x4',
                            linkText: 'View'
                        },
                        {
                            img: James,
                            title: 'Barefoot in The Park',
                            creator: 'James Blake, Rosalia',
                            link: 'https://www.youtube.com/watch?v=Lnvobi3ctsE',
                            linkText: 'View'
                        },
                        {
                            img: Fang,
                            title: 'You’re the One...',
                            creator: 'Lo-Fang',
                            link: 'https://www.youtube.com/watch?v=jYluMAO1b7Y',
                            linkText: 'View'
                        },
                        {
                            img: Luna,
                            title: 'Be the One',
                            creator: 'Luna Shadows',
                            link: 'https://www.youtube.com/watch?v=Qeo60N0oPy4',
                            linkText: 'View'
                        },
                    ],
                },
                {
                    type: 'text',
                    value: 'Here’s the list of my top 5 books. 📕'
                },  
                {
                    type: 'Books I read avidly',
                    appList: [
                        {
                            img: Art,
                            title: 'Art',
                            description: '1994',
                            creator: 'Yasmina Reza',
                            link: 'https://www.goodreads.com/book/show/284022._Art_',
                            linkText: 'View'
                        },
                        {
                            img: Carnage,
                            title: 'The God of Carnage',
                            description: '2006',
                            creator: 'Yasmina Reza',
                            link: 'https://www.goodreads.com/book/show/3070605-the-god-of-carnage',
                            linkText: 'View'
                        },
                        {
                            img: Immortal,
                            title: 'Immortality',
                            description: '1990',
                            creator: 'Milan Kundera',
                            link: 'https://www.goodreads.com/book/show/28634.Immortality',
                            linkText: 'View'
                        },
                        {
                            img: Blind,
                            title: 'Blindness',
                            description: '1995',
                            creator: 'José Saramago',
                            link: 'https://www.goodreads.com/book/show/40495148-blindness',
                            linkText: 'View'
                        },
                        {
                            img: Animal,
                            title: 'Animal Farm',
                            description: '1945',
                            creator: 'George Orwell',
                            link: 'https://www.goodreads.com/book/show/170448.Animal_Farm',
                            linkText: 'View'
                        },
                    ],
                }, 
                {
                    type: 'text',
                    value: 'And finaly, my top 5 movies of all time. 🎬'
                },
                {
                    type: 'My go-to movies',
                    appList: [
                        {
                            img: Bruges,
                            title: 'In Bruges',
                            description: '2008',
                            creator: 'Martin McDonagh',
                            link: 'https://www.imdb.com/title/tt0780536',
                            linkText: 'View'
                        },
                        {
                            img: Detachment,
                            title: 'Detachment',
                            description: '2011',
                            creator: 'Tony Kaye',
                            link: 'https://www.imdb.com/title/tt1683526',
                            linkText: 'View'
                        },
                        {
                            img: Pulp,
                            title: 'Pulp Fiction',
                            description: '1994',
                            creator: 'Quentin Tarantino',
                            link: 'https://www.imdb.com/title/tt0110912',
                            linkText: 'View'
                        },
                        {
                            img: Jagten,
                            title: 'Jagten',
                            description: '2012',
                            creator: 'Thomas Vinterberg',
                            link: 'https://www.imdb.com/title/tt2106476',
                            linkText: 'View'
                        },
                        {
                            img: Earth,
                            title: 'Another Earth',
                            description: '2011',
                            creator: 'Mike Cahill',
                            link: 'https://www.imdb.com/title/tt1549572',
                            linkText: 'View'
                        },
                    ],
                },
                {
                    type: 'text',
                    value: 'So this is it.'
                },
                {
                    type: 'text',
                    value: 'The epilogue to how I know Hooman so far! ☺'
                },                        
            ]
        }
    ],
    footer: {
        mail: 'hooman.hatefi@gmail.com',
        goToTopIcon: GoToTop,
        socials: [
            {
                alt: 'Linkedin',
                img: LI,
                url: 'https://www.linkedin.com/in/h8man'
            },
            {
                alt: 'Behance',
                img: BE,
                url: 'https://www.behance.net/h8man'
            },
            {
                alt: 'Dribbble',
                img: DR,
                url: 'https://dribbble.com/H8man'
            },
            {
                alt: 'Medium',
                img: ME,
                url: 'https://h8man.medium.com'
            },
            {
                alt: 'Twitch',
                img: TH,
                url: 'https://www.twitch.tv/h8man13'
            },
            {
                alt: 'Spotify',
                img: SP,
                url: 'https://open.spotify.com/user/hooman13'
            },
            {
                alt: 'Twitter',
                img: TW,
                url: 'https://twitter.com/h8man'
            },
            {
                alt: 'Instagram',
                img: IN,
                url: 'https://www.instagram.com/h8man.hatefi'
            },
            {
                alt: 'Goodreads',
                img: GR,
                url: 'https://www.goodreads.com/user/show/10197210-hooman-hatefi'
            },            
        ]
    }
};


export default data;