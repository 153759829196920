import React, { memo, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { navigateToSection, themeColors, useRender } from '../utils';
import Collapsible from 'react-collapsible';
//header
import Switch from 'react-ios-switch';
import { ReactComponent as ArrowDown } from '../assets/svg/ArrowDown.svg';
import { ReactComponent as Moon } from '../assets/svg/Moon.svg';
import Hlogo from '../assets/svg/Logo.svg';
import HLogoDark from '../assets/svg/HLogoDark.svg';
import { isDesktop } from '../utils';
import gsap from 'gsap';
import Img from './Img';

const Hamburger = memo(({ navMenuOpen, toggleNavMenu }) => {
    return (
        <div
            id="nav-icon4"
            onClick={toggleNavMenu}
            className={navMenuOpen ? 'open' : ''}
        >
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
});

function Header({ theme, setTheme, socials=[], navigations=[], currentPage: page ='' }) {
    useRender();
    const navRef = useRef();

    const isDarkTheme = theme === 'dark';
    const [navMenuOpen, setnavMenuOpen] = useState(isDesktop);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(page);

    useEffect(() => {
        setCurrentPage(page);
    }, [page])

    function NavSection({ children }) {
        return (
            <div className={'navSectionWrapper'}>
                {children}
            </div>
        );
    }

    function NavItem({ text, link, Icon, externalLink, download = false }) {
        const isActive = link === currentPage;
        return (
            <a 
                href={externalLink || link}
                download={download}
                className={`df jcbw navItemWrapper ${isActive? 'activeItem': ''}`} 
                onClick={(e) => {
                    if (!externalLink) {
                        e.preventDefault();
                        !isDesktop() && setnavMenuOpen(false);
                        setTimeout(() => {
                            setCurrentPage(link);
                        }, 750);
                        setTimeout(() => {
                            navigateToSection(`#${link}`)
                        }, 100);
                    }
                }}
            >
                <span>{text}</span>
                <Icon className={isDarkTheme ? 'footer_email--dark' : 'footer_email--light'} width='20' height='20' />
            </a>
        );
    }

    function SocialItem({ text, link, Icon }) {
        return (
            <a href={link} target="_blank" rel="noreferrer" className='df jcbw navItemWrapper socialItem'>
                <span>{text}</span>
                <Img src={Icon} className={isDarkTheme ? 'footer_email--dark' : 'footer_email--light'} width='20' height='20' />
            </a>
        );
    }

    useLayoutEffect(() => {
        for (const [varName, value] of Object.entries(themeColors[theme])) {
            document.documentElement.style.setProperty(`--${varName}`, value);
        }
    }, [theme]);

    useLayoutEffect(() => {
        const osTheme = window.matchMedia('(prefers-color-scheme: dark)');
        setTheme(osTheme.matches ? "dark" : "light");
        osTheme.addEventListener('change', e => setTheme(e.matches ? "dark" : "light"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        const copy = navRef.current;
        if (navMenuOpen) {
            gsap.to(copy, {
                translateX: '0',
                duration: .25,
                opacity: 1
            })
        } else {
            gsap.to(copy, {
                translateX: '-100%',
                duration: .25
            })
        };
    }, [navMenuOpen])

    return (
        <header className='df aic header'>
            <Hamburger navMenuOpen={navMenuOpen} toggleNavMenu={() => setnavMenuOpen(!navMenuOpen)} />
            <Img src={isDarkTheme ? HLogoDark : Hlogo} className='mx-auto' height='40' width='40' />
            <nav className='navContaiver' ref={navRef}>
                { isDesktop() && 
                    <div className='desktopNav_title'>
                        At a glance
                    </div>
                }
                {
                    navigations.map( (navItems, index) => (
                        <NavSection  key={index}>
                            {navItems.map(navItem => (
                                <NavItem
                                    key={navItem.text}
                                    text={navItem.text}
                                    link={navItem.anchor}
                                    Icon={navItem.svgIcon}
                                    externalLink={navItem.externalLink}
                                    download={navItem.download}
                                />
                            ))}
                        </NavSection>
                    ))
                }
                <NavSection>
                    <div className='df jcbw aic navItemWrapper'>
                        <span style={{ order: isDesktop() && 2 }}>Dark Mode</span>
                        {isDesktop() &&
                            <Moon
                                style={{ order: isDesktop() && 3 }}
                                className={isDarkTheme ? 'footer_email--dark' : 'footer_email--light'}
                                width='20'
                                height='20'
                            />
                        }
                        <Switch
                            checked={isDarkTheme}
                            onChange={() => setTheme(isDarkTheme ? 'light' : 'dark')}
                            offColor="#D1D1D6"
                            style={{
                                transform: 'scale(.8, .8)',
                                margin: '-5px 0',
                                marginLeft: isDesktop() ? 'auto' : '',
                                order: isDesktop() && 1
                            }}
                        />
                    </div>
                </NavSection>
                {isDesktop() ?
                    <div>
                        <div className='desktopNav_title'>
                            Social Media
                        </div>
                        <aside>
                            {
                                socials.map( socialsItem => (
                                    <SocialItem
                                        key={socialsItem.text}
                                        link={socialsItem.url}
                                        Icon={socialsItem.icon}
                                        text={socialsItem.text}
                                    />
                                ))
                            }
                        </aside>
                    </div>
                    :
                    <NavSection>
                        <Collapsible
                            transitionTime='200'
                            open={drawerOpen}
                            onOpen={() => setDrawerOpen(true)}
                            onClose={() => setDrawerOpen(false)}
                            trigger={
                                <div className='df jcbw navItemWrapper'>
                                    <span>Social Media</span>
                                    <ArrowDown
                                        className={isDarkTheme ? 'footer_email--dark' : 'footer_email--light'}
                                        style={{
                                            transition: 'transform .2s',
                                            transform:
                                                drawerOpen ?
                                                    'translate(0, 6px) rotate(180deg)' :
                                                    'translate(0, 6px) rotate(0deg)'
                                        }}
                                    />
                                </div>
                            }
                        >
                            <aside className='navContaiver_links'>
                                {socials.map((socialObj, index) => {
                                    return (
                                        <a href={socialObj.url} target="_blank" rel="noreferrer" key={index}>
                                            {socialObj.text}
                                        </a>
                                    )
                                })}
                            </aside>
                        </Collapsible>
                    </NavSection>
                }
            </nav>
        </header>
    );
}

export default memo(Header);